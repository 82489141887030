import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
// import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  introSection,
  // description,
  row,
  // col6,
  col12,
  // marginTopSubstitute,
} from "./subpage.module.scss";

const NotFoundPage = () => {
  return (
    <Layout pageTitle="404 Seite nicht gefunden" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container neutralPageWidth={true}>
            <div className={row}>
              <div className={col12}>
                {/* <p className={description}></p> */}
                <h1>
                  Entschuldigung, die gewünschte Seite konnte nicht gefunden
                  werden.
                </h1>
                <p className="text-medium">
                  Gründe dafür können sein, dass eine falsche oder veraltete URL
                  aufgerufen wurde – bitte überprüfen Sie diese noch einmal.
                  Oder die betreffende Seite wurde inzwischen archiviert,
                  verschoben oder umbenannt.
                </p>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
